<template>
  <div>
    <gl-title title="订单结算"></gl-title>
    <div style="margin: 18px;">
      <el-button type="primary" size="mini" @click="mClickAction('mUpdateVisible')"> 结算设置 </el-button>
    </div>
    <gl-page-header>
      <el-form :model="mFormData" ref="listForm" :inline="true" size="mini">
        <el-form-item prop="orderId" label="订单搜索">
          <gl-list-search v-model="mFormData.orderId" placeholder="订单ID"></gl-list-search>
        </el-form-item>
        <el-form-item prop="isSettlement" label="是否结算">
          <gl-option v-model="mFormData.isSettlement" :list="closeoutEnum"></gl-option>
        </el-form-item>
        <el-form-item prop="buyerName" label="下单人昵称">
          <gl-list-search v-model="mFormData.buyerName" placeholder="下单人昵称"></gl-list-search>
        </el-form-item>
        <el-form-item prop="settlementTimeStart" label="结算时间">
          <gl-date-picker :startTime.sync="mFormData.settlementTimeStart" :endTime.sync="mFormData.settlementTimeEnd"></gl-date-picker>
        </el-form-item>
        <el-form-item prop="createTimeStart" label="创建时间">
          <gl-date-picker :startTime.sync="mFormData.createTimeStart" :endTime.sync="mFormData.createTimeEnd"></gl-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button  type="primary" icon="el-icon-search" @click="mReload"> 搜索 </el-button>
          <el-button icon="el-icon-refresh" @click="mResetForm('listForm')"> 重置 </el-button>
        </el-form-item>
      </el-form>
    </gl-page-header>
    <gl-card v-loading="mLoading">
      <el-table :data="mTableData" style="width: 100%">
        <el-table-column type="index" :index="mIndexMethod" label="序号" align="center" min-width="30"></el-table-column>
        <el-table-column prop="orderId" label="订单ID" width="150"></el-table-column>
        <el-table-column prop="itemName" label="商品标题" width="130px" show-overflow-tooltip></el-table-column>
        <el-table-column prop="skuName" label="商品规格"></el-table-column>
        <el-table-column prop="buyerName" label="下单人昵称"></el-table-column>
        <el-table-column prop="payType" label="付款方式">
          <template slot-scope="{row}">
            <span>{{payTypeMode[row.payType]}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="totalPrice" label="金额"></el-table-column>
        <el-table-column prop="totalDiscount" label="优惠金额"></el-table-column>
        <el-table-column prop="payPrice" label="实付金额"></el-table-column>
        <el-table-column prop="createTime" label="订单时间"></el-table-column>
        <el-table-column prop="settlementTime" label="订单结算时间"></el-table-column>
        <el-table-column prop="isSettlement" label="是否结算">
          <template slot-scope="{row}">
            <span v-if="row.isSettlement == 1">已结算</span>
            <span v-else>未结算</span>
          </template>
        </el-table-column>
        <el-table-column prop="name" label="操作" width="130px" fixed="right">
          <template slot-scope="scope">
            <el-button type="text" @click="$router.push({path: 'detail', query:{orderId: scope.row.orderId}})"> 查看 </el-button>
          </template>
        </el-table-column>
      </el-table>
      <gl-pagination
        :total="mTotal"
        :current-page="mPageNum"
        @currentChange="mHandleCurrentChange"
        @sizeChange="mHandleSizeChange">
      </gl-pagination>
    </gl-card>
    <settlementSetting :visible.sync="mUpdateVisible"/>
  </div>
</template>

<script>
import {closeoutList} from '@/api/backendall/finance'
import ListMixin from '@/mixins/list.mixin.js'
import settlementSetting from './settlementSetting.vue'
export default {
  name: 'closeoutIndex',
  components: {settlementSetting},
  mixins: [ListMixin],
  data(){
    return{
      closeoutEnum: [
        {name: '已结算', value: '1'},
        {name: '未结算', value: '2'},
      ],
      payTypeMode: {
        '1': '微信',
        '2': '支付宝',
        '3': '余额',
        '4': '线下支付',
      },
    }
  },
  mounted(){
    this.mGetListFun = closeoutList
    this.mGetList()
  },
}
</script>

<style>

</style>