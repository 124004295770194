<template>
  <el-dialog 
    title="订单状态"
    :visible="visible"
    :close-on-click-modal="true"
    :before-close="handleClose"
    width="600px"
  > 
    <gl-wrap right="30px" v-loading="loading">
      <el-form :model="formData" ref="form" label-width="130px" width="500px" size="mini">
        <el-form-item prop="official" label="官方分成比例%">
          <el-input v-model.number="formData.official" :maxlength="3"></el-input>
        </el-form-item>
        <el-form-item prop="cityLevel" label="大喜分成比例%（城市级代理）">
          <el-input v-model.number="formData.cityLevel" :maxlength="3"></el-input>
        </el-form-item>
        <el-form-item prop="regionLevel" label="小喜分成比例%（区域级代理）">
          <el-input v-model.number="formData.regionLevel" :maxlength="3"></el-input>
        </el-form-item>
        <el-form-item prop="fans" label="喜粉分成比例%（推广人）">
          <el-input v-model.number="formData.fans" :maxlength="3"></el-input>
        </el-form-item>
        <el-form-item prop="designer" label="设计师分成%（按出厂价计算）">
          <el-input v-model.number="formData.designer" :maxlength="3"></el-input>
        </el-form-item>
        <el-form-item prop="invest" label="投资人分成%（按出厂价计算）">
          <el-input v-model.number="formData.invest" :maxlength="3"></el-input>
        </el-form-item>
        
      </el-form>
    </gl-wrap>
    <gl-flex>
      <el-button type="primary" @click="onSubmit">确认</el-button>
      <el-button @click="handleClose" >关闭</el-button>
    </gl-flex>
  </el-dialog>
</template>

<script>
import {getCloseoutSet,editCloseoutSet} from '@/api/backendall/finance'
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    itemId: {
      type: String,
      default: ''
    },
  },
  data(){
    return{
      loading: false,
      formData: {},
    }
  },
  watch: {
    visible: {
      handler: function(newVal){
        newVal && this.getSet()
      }
    }
  },
  methods: {
    onSubmit(){
      this.$refs.form.validate(async valid => {
        if(!valid) return false
        this.loading = true
        const res = await editCloseoutSet(this.formData)
        this.$message.success('操作成功')
        this.handleClose()
        this.loading = false
      })
    },
    async getSet(){
      this.loading = true
      const res = await getCloseoutSet()
      this.formData = res.data
      this.loading = false
    },
    handleClose(){
      this.$emit('update:visible', false)
      this.formData = {}
      this.$refs.form.resetFields()
    }
  },
}
</script>

<style>

</style>